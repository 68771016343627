/* eslint-disable no-unused-vars */

// Set of Mina events
export enum Event {
  pageViewed = "Page Viewed",
  buttonClicked = "Button Clicked",
  formSubmitted = "Form Submitted",
  formErrored = "Form Errored",
  formUpdated = "Form Updated",

  // event descriptions from legacy event names
  // Invalid pages
  errorPage = "page_error",
  notFoundPage = "page_404",
  longLoad = "page_longload",
  // Bad e-mail address - just to see how often this happens
  invalidEmail = "email_invalid_mx_waitlist",
  // Parent dashboard
  mainShowBanner = "dashboard_banner",
  mainMoreSeats = "dashboard_click_moreseats",
  mainShowSetSchedule = "dashboard_alert_setschedule",
  mainAddWaitlist = "dashboard_click_addwaitlist",
  mainRequestEnrollment = "dashboard_click_requestenrollment",
  mainPayDeposit = "dashboard_click_paydeposit",
  mainCancelReactivate = "dashboard_reactivate_cancel",
  mainPassSave = "dashboard_reactivate_save",
  mainChangeProgram = "dashboard_change_program",
  mainChangeDNCB = "dashboard_change_dncb",
  mainChangeDate = "dashboard_change_date",
  mainNoSavedPrograms = "dashboard_click_nosavedprograms",
  mainShareLegUp = "dashboard_click_share",
  mainShareSocialChoice = "dashboard_share_social", // metadata is social provider
  mainShareFacebook = "dashboard_share_facebook", // metadata is sharing type
  // Provider dashboard
  dashboarTextOptIn = "dashboard_click_textoptin",
  dashboardTourSchedule = "dashboard_click_settourschedule",
  dashboardViewComments = "dashboard_click_surveycomments",
  // Tips
  tipDismiss = "dashboard_tip_dismiss", // metadata is specific tip
  // Add waitlist (parent)
  addWaitlistClose = "addwaitlist_click_close",
  addWaitlistCancel = "addwaitlist_click_cancel",
  addWaitlistOK = "addwaitlist_click_ok",
  // Action center
  actionSwitchToClassic = "action_switch_classic",
  actionCount = "action_count",
  actionSelectState = "action_select_state",
  actionSaveState = "action_save_state",
  actionCancelState = "action_cancel_state",
  actionStripe = "action_stripe_setup",
  actionPassSave = "action_pass_save",
  actionChangeProgram = "action_change_program",
  actionChangeDNCB = "action_change_dncb",
  actionChangeDate = "action_change_date",
  actionChangeWaiver = "action_click_changewaiver",
  actionChangeContract = "action_click_changecontract",
  actionRemoveFromWaitlist = "action_click_removefromwaitlist",
  actionDeleteTour = "action_click_deletetour",
  actionWaiveCheckbox = "action_click_waivedeposit",
  actionWaiveDialog = "action_waive_dialog",
  actionTourTab = "action_tourtab_click",
  actionDownloadPayments = "action_click_downloadpayments",
  actionProfilePass = "action_pass_profile_family",
  // Age groups
  ageGroupsAdd = "action_add_agegroup",
  ageGroupsDelete = "action_delete_agegroup",
  ageGroupsEdit = "action_edit_agegroup",
  ageGroupsSendParentUpdate = "action_send_parentupdate",
  ageGroupsViewWaitlist = "action_view_waitlist",
  // Classrooms
  classroomCancel = "classroom_edit_cancel",
  classroomSave = "classroom_edit_save",
  // Enrollments
  enrollmentCancel = "enrollment_edit_cancel",
  // Setup Wizard
  // Navigation
  setupStart = "setup_start",
  setupComplete = "setup_complete",
  setupNext = "setup_click_next", // metadata is page on which next is clicked
  setupBack = "setup_click_back", // metadata is page on which back is clicked
  setupCancel = "setup_click_cancel", // metadata is page on which close button is clicked
  // Page interaction
  setupGetStarted = "setup_click_getstarted",
  setupSeeDashboard = "setup_click_dashboard",
  setupAddProgram = "setup_click_addprogram",
  setupUploadPrograms = "setup_click_uploadprograms",
  setupAddContract = "setup_click_addcontract",
  setupCycleProgram = "setup_cycle_program",
  setupAddAgeGroup = "setup_click_addagegroup",
  setupStripe = "setup_click_stripe",
  setupCheckUploadHandbook = "setup_check_uploadhandbook",
  setupCheckUploadHealth = "setup_check_uploadhealth",
  setupCheckUploadWaitlistForm = "setup_check_uploadwaitlistform",
  setupCheckUploadWaitlist = "setup_check_uploadwaitlist",
  setupCheckUploadLogo = "setup_check_uploadlogo",
  setupCheckUploadDepositForm = "setup_check_uploaddepositform",
  setupUploadHandbook = "setup_click_uploadhandbook",
  setupUploadHealth = "setup_click_uploadhealth",
  setupUploadWaitlistForm = "setup_click_uploadwaitlistform",
  setupUploadWaitlist = "setup_click_uploadwaitlist",
  setupUploadLogo = "setup_click_uploadlogo",
  setupUploadDepositForm = "setup_click_uploaddepositform",
  // Tour items
  tourAddClick = "tour_click_add",
  tourDateCancel = "action_cancel_tourdate",
  tourDateSave = "action_save_tourdate",
  tourDateUpdate = "action_update_tourDate",
  tourCompleted = "action_click_completed",
  tourNoShow = "action_click_noshow",
  tourRequestReschedule = "action_click_requestreschedule",
  tourSendMail = "action_click_sendmail",
  tourChange = "action_change_tourdate",
  tourPrevWeek = "tour_week_prev",
  tourNextWeek = "tour_week_next",
  tourDetailsClose = "tour_details_close",
  tourDetailsChange = "tour_details_change",
  tourDetailsCancel = "tour_details_cancel",
  tourChangeStateClose = "tour_changestate_close",
  tourChangeState = "tour_changestate",
  tourFutureDowload = "tour_futuredownload",
  // Tour schedules
  tourScheduleAdd = "tourschedule_click_add",
  tourScheduleEdit = "tourschedule_click_edit",
  tourScheduleChange = "tourschedule_click_save",
  tourScheduleAddVacationDay = "tourschedule_add_vacation",
  tourScheduleRemoveVacationDay = "tourschedule_delete_vacation",
  tourScheduleExactDateChange = "tourschedule_exactdate_click",
  tourScheduleExactDateDelete = "tourschedule_exactdate_delete",
  // Seats
  seatCount = "seat_count",
  seatNoClassrooms = "seat_error_noclassrooms",
  seatExpandClassroom = "seat_classroom_expand",
  seatFilterStatus = "seat_filter_status",
  seatFilterCenter = "seat_filter_center",
  seatClickCard = "seat_click_card",
  seatAddSeat = "seat_click_addseat",
  seatClickFilledSeat = "seat_click_filledseat",
  seatViewFilled = "seat_click_filled",
  seatViewOpened = "seat_click_opened",
  seatViewDeposit = "seat_click_depositform",
  seatUnfill = "seat_click_unfill",
  seatAnalysis = "seat_show_whatif",
  seatSendOfferLegupFamily = "seat_sendoffer_legupfamily",
  seatDontSendOfferLegupFamily = "seat_sendoffer_dontsend",
  seatSendOverOfferQuota = "seat_sendoffer_overquota",
  seatSuggestedUpdate = "seat_modify_accept",
  seatNoSuggestedUpdate = "seat_modify_reject",
  // Filled seats
  seatFillEdit = "seat_fill_click_edit",
  seatFillRemove = "seat_fill_click_remove",
  seatFillLegup = "seat_fill_click_legup",
  seatFillSchedule = "seat_fill_toggleschedule",
  seatDncbFilter = "seat_fill_toggledncb",
  seatPreferencesFilter = "seat_fill_togglePreferences",
  seatAllWaitlistsFilter = "seat_fill_toggleallwaitlists",
  seatExport = "seat_click_export",
  seatExportCancel = "seat_export_cancel",
  seatExportSave = "seat_export_save",
  seatManualFillClose = "seat_fill_manual_close",
  // Edit Seat card
  seatEditClose = "seat_edit_close",
  seatEditSave = "seat_edit_save",
  seatEditOffer = "seat_edit_hold",
  seatEditCopy = "seat_edit_copy",
  seatEditPause = "seat_edit_pause",
  seatEditQRCode = "seat_edit_qrcode",
  seatEditRemove = "seat_edit_remove",
  seatEditOpen = "seat_edit_open",
  seatEditHistory = "seat_edit_history",
  seatEditSplit = "seat_edit_split",
  seatEditFill = "seat_edit_fill",
  seatNearHitsClose = "seat_nearhits_close",
  seatRemoveCancel = "seat_remove_cancel",
  seatRemoveSave = "seat_remove_save",
  seatOfferChooseFamily = "seat_offer_family_click",
  seatOfferChooseFamilyClose = "seat_offer_family_close",
  seatOfferChooseFamilySave = "seat_offer_family_save",
  seatOfferChooseState = "seat_offer_state_select",
  seatOfferChooseContract = "seat_offer_contract_select",
  seatOfferSetUpStripe="seat_offer_stripe",
  seatOfferClose = "seat_offer_close",
  seatOfferSave = "seat_offer_save",
  // Split Seat card
  splitSeatClose = "seat_split_close",
  // Tuition
  tuitionSend = "tuition_click_send",
  tuitionAddFamilySave = "tuition_addfamily_save",
  tuitionUpdate = "tuition_update_click",
  // Profile page
  profileSwitchBanner = "profile_banner_switch",
  // Provider file interactions
  providerFileDelete = "provider_file_delete",
  providerFileUpdate = "provider_file_update",
  providerFileSetAgeGroups = "provider_file_agegroups",
  providerTrxPaymentsOptIn = "provider_trx_payments_opt_in",
  // Marketplace
  holdSeatChooseChild = "marketplace_hold_child_open",
  holdSeatCancelChooseChild = "marketplace_hold_child_close",
  holdSeatChildChoosen = "marketplace_hold_child_select",
  // Navigation tab clicks
  // Note that prior to 8/30/21, the format of this event was "tab_click_{page}"
  // Whereas after the 8/30 relesae the format is "tab_click" with page as metadata
  clickTab = "tab_click",
  clickValidate = "tab_click_validate", // note unlike other clicks, this is called when page is loaded
  // Contract List clicks
  contractAdd = "contract_click_add",
  contractDuplicate = "contract_click_copy",
  contractEdit = "contract_click_edit",
  contractRemove = "contract_click_remove",
  contractDelete = "contract_delete",
  contractCancel = "contract_edit_cancel",
  contractSave = "contract_edit_save",
  // Leads
  leadAdd = "lead_click_add",
  leadSave = "lead_click_save",
  // Program List clicks
  programAdd = "program_click_add",
  programEdit = "program_click_edit",
  programDuplicate = "program_click_copy",
  programRemove = "program_click_remove",
  programDelete = "program_delete",
  programCancel = "program_edit_cancel",
  programSave = "program_edit_save",
  programMoveContract = "program_movecontract",
  // Waitlist fees
  waitlistFeeEdit = "waitlistfee_edit",
  waitlistFeeCancel = "waitlistfee_cancel",
  waitlistFeeSave = "waitlistfee_save",
  // Center Cards
  centerCardClick = "info_click_centercard",
  centerCardAdd = "info_click_add",
  centerCardAddDialog = "info_add",
  // Info page
  infoAddLegupWaitlist = "info_legup_waitlist",
  infoClickFile = "info_link_file",
  infoClickHandbook = "info_link_handbook",
  infoClickLicense = "info_link_license",
  infoClickMap = "info_link_map",
  infoClickSafety = "info_link_safety",
  infoClickWebpage = "info_link_website",
  infoClickSeat = "info_seat_click",
  infoClickCenter = "info_center_click",
  infoClickSeatsAvailable = "info_click_seatsavailable",
  infoSaveParentAddress = "info_address_save",
  infoListingTabClick = "info_listheader_click",
  // Concierge interactions
  conciergeClickCenter = "concierge_center_click",
  conciergeClickSeat = "conceirge_seat_click",
  // Parent contact
  contactClose = "contact_click_close",
  contactEditAnswers = "contact_click_editAnswers",
  contactRemoveWaitlist = "contact_click_remove",
  contactRemoveDialog = "contact_remove",
  contactSave = "contact_click_save",
  contactSendOffer = "contact_click_sendOffer",
  contactSetPrograms = "contact_setprograms",
  contactCancelEditAnswers = "contact_editAnswers_close",
  contactSaveAnswers = "contact_editAnswers_save",
  tourContactClose = "contact_tour_click_close",
  // Family page
  familyAddParent = "family_click_addparent",
  familyConfirmTextOptin = "family_confirm_textoptin",
  familyConfirmChangeEmail = "family_confirm_changeemail",
  familySaveParent = "family_click_saveparent", // metadata - parent_id or "add"
  familySaveChild = "family_click_savechild", // metadata - child_id
  familySaveSubsidies = "family_click_savesubsidies",
  familyView = "family_click_viewfamily",
  familyViewLead = "family_click_viewlead",
  familyReactivate = "family_click_reactivate",
  familyMessage = "family_click_message",
  familyRequestPayment = "family_request_payment",
  // Waitlist
  waitlistClick = "waitlist_click_row", // metadata - child_id_spot_id
  waitlistPosition = "waitlist_position_details",
  waitlistPositionUseful = "waitlist_position_useful",
  // Signup page
  signupError = "signup_error", // metadata - page URL
  signupVisit = "signup_visit", // metadata - page URL
  signupComplete = "signup_complete", // metadata - page URL
  // User page
  userChangeAvatar = "user_change_avatar",
  userSave = "user_click_save",
  userUpdateNotifications = "user_change_notifications",
  // Display Options
  displayOptionChangeCancel = "displayoption_change_cancel",
  displayOptionChangeSave = "displayoptions_change_save",
  // Subsidies
  subsidiesChangeCancel = "subisides_change_cancel",
  subsidiesChangeSave = "subsidies_change_save",
  subsidiesEditCancel = "subsidies_edit_cancel",
  subsidiesEditSave = "subsidies_edit_save",
  subsidiesEditAddCity = "subsidies_edit_addcity",
  subsidiesEditAddCounty = "subsidies_edit_addcounty",
  // Tour policies
  policyChangeCancel = "tourpolicy_change_cancel",
  policyChangeSave = "tourpolicy_change_save",
  // Schedules
  scheduleChangeCancel = "schedule_change_cancel",
  scheduleChangeSave = "schedule_change_save",
  // Marketplace
  marketFilter = "market_click_filter",
  marketSaveFilter = "market_filter_save",
  marketCloseFilter = "market_filter_close",
  marketToggleMap = "market_map_toggle",
  // Family marketplace mailing preferences
  mailPrefsChangeCancel = "mailprefs_change_cancel",
  mailPrefsSaveChange = "mailprefs_change_save",
  // Coupons
  couponAdd = "coupon_click_add",
  couponEdit = "coupon_click_edit",
  couponRemove = "coupon_click_delete",
  couponCancel = "coupon_edit_cancel",
  couponSubmit = "coupon_submit",
  // QR Fun
  showQRCode = "qrcode_show",
  // Child query
  childQueryClick = "child_query_click",
  childQueryClose = "child_query_close",
  // Cleaning
  cleanCanText = "clean_cantext_click",
  // Concierge
  conciergeLaunch = "concierge_survey_open",
  conciergeStarted = "concierge_survey_started",
  conciergeClose = "concierge_survey_close",
  conciergeComplete = "concierge_survey_complete",
  conciergeSurveyPage = "concierge_survey_page",
  conciergeStay = "concierge_survey_confirm_exit",
  conciergeSurveyResultsFavorite = "concierge_survey_results_favorite",
  conciergeSurveyResultsThumbsUp = "concierge_survey_results_thumbsup",
  conciergeSurveyResultsThumbsDown = "concierge_survey_results_thumbsdown",
  conciergeSurveyResultsSeeMore = "concierge_survey_results_more",
  conciergeSurveyResultsConfirmSeats = "concierge_survey_results_confirm_seats",
  conciergeSurveyResultsConfirmTour = "concierge_survey_results_confirm_tour",
  conciergeSurveyResultsAddTour = "concierge_survey_results_add_tour", // metadata is "yes" if tour added, "no" if tour not added
  conciergeSurveyResultsConfirmWaitlist = "concierge_survey_results_confirm_waitlist",
  conciergeSurveyResultsAddWaitlist = "concierge_survey_results_add_waitlist", // metadata is "yes" if signed up for waitlist, "no" if not
  conciergeShowResults = "concierge_results_show",
  conciergeResultAction = "concierge_results_click",
  conciergeResultFavorite = "concierge_results_favorite",
  conciergeResultThumbsDown = "concierge_results_thumbsdown",
  conciergeExitPage = "concierge_page_exit",
  // Onboarding Survey
  onboardingLaunch = "onboard_survey_open",
  onboardingUploadWaitlist = "onboard_survey_upload_waitlist",
  onboardingStripeSetup = "onboard_survey_stripe",
  // Validation page
  validateSubmit = "validate_submit",
  validateChangeProgram = "validate_change_program",
  validateChangeDate = "validate_change_date",
  validateChangeDNCB = "validate_change_dncb",
  validateChangeOther = "validate_change_other",
  validateChangeDeactivate = "validate_change_deactivate",
  validatePassSave = "validate_pass_save",
  validateOfferClick = "validate_offer_click",
  validateSignup = "validate_click_signup",
  validateChooseContract = "validate_select_contract",
  validateChooseRemoveOptions = "validate_select_remove",
  validateInvalid = "validate_error_load",
  validateBadCode = "validate_error_code",
  // Center form
  formParentFirstName = "centerform_parent_first_name",
  formParentLastName = "centerform_parent_last_name",
  formParentEmail = "centerform_parent_email",
  formParentPhone = "centerform_parent_phone",
  formParentWorkPhone = "centerform_parent_workphone",
  formParentCellPhone = "centerform_parent_cellphone",
  formParentCanText = "centerform_parent_cantext",
  formParentPrimaryPhone = "centerform_parent_primaryphone",
  formParentCompany = "centerform_parent_company",
  formParentAddress1 = "centerform_parent_address1",
  formParentAddress2 = "centerform_parent_address2",
  formParentCity = "centerform_parent_city",
  formParentState = "centerform_parent_state",
  formParentZip = "centerform_parent_zip",
  formParentSubsidy = "centerform_parent_subsidy",
  formParent1Expand = "centerform_parent1_expand",
  formParent2Expand = "centerform_parent2_expand",
  formChildExpand = "centerform_child_expand",
  formSubsidyExpand = "centerform_subsidy_expand",
  formQuestionsExpand = "centerform_questions_expand",
  formSpotExpand = "centerform_spot_expand",
  formParent2FirstName = "centerform_parent2_first_name",
  formParent2LastName = "centerform_parent2_last_name",
  formParent2Email = "centerform_parent2_email",
  formParent2Phone = "centerform_parent2_phone",
  formParent2WorkPhone = "centerform_parent2_workphone",
  formParent2CellPhone = "centerform_parent2_cellphone",
  formParent2CanText = "centerform_parent2_cantext",
  formParent2PrimaryPhone = "centerform_parent2_primaryphone",
  formParent2Company = "centerform_parent2_company",
  formParent2Address1 = "centerform_parent2_address1",
  formParent2Address2 = "centerform_parent2_address2",
  formParent2City = "centerform_parent2_city",
  formParent2State = "centerform_parent2_state",
  formParent2Zip = "centerform_parent2_zip",
  formParent2Subsidy = "centerform_parent2_subsidy",
  formChildGender = "centerform_child_gender",
  formChildFirstName = "centerform_child_firstname",
  formChildLastName = "centerform_child_lastname",
  formChildTrying = "centerform_child_trying",
  formChildBirthdate = "centerform_child_birthdate",
  formChildSameAddress = "centerform_child_sameaddress",
  formChildAddress1 = "centerform_child_address1",
  formChildAddress2 = "centerform_child_address2",
  formChildCity = "centerform_child_city",
  formChildState = "centerform_child_state",
  formChildZip = "centerform_child_zip",
  formSpotEnrollment = "centerform_spot_date_preferred",
  formSpotComments = "centerform_spot_comments",
  formSpotReferral = "centerform_spot_referral",
  formCustomAnswer = "centerform_spot_custom_answer",
  formButtonNext = "centerform_click_next",
  formAlertRequired = "centerform_alert_required",
  formAlertSameEmail = "centerform_alaert_sameemail",
  formSelectProgram = "centerform_program_select",
  formSelectProgramDays = "centerform_program_selectdays",
  formSubmitPrograms = "centerform_click_programs",
  formSubmitPayment = "centerform_click_payment",
  formAchVerified = "centerform_payment_ach_verified",
  formPaymentName = "centerform_payment_name",
  formPaymentSameAddress = "centerform_payment_sameaddress",
  formPaymentAddress1 = "centerform_payment_address1",
  formPaymentAddress2 = "centerform_payment_address2",
  formPaymentCity = "centerform_payment_city",
  formPaymentState = "centerform_payment_state",
  formPaymentZip = "centerform_payment_zip",
  formPaymentError = "centerform_payment_error",
  formPaymentACH = "centerform_payment_ach",
}

function extractUtmParams(url) {
  const urlObject = new URL(url);
  const utmParams = {};

  urlObject.searchParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  });

  return utmParams;
}

export const identifyUser = (user_id: string, traits: object) => {
  const utmParams = extractUtmParams(window.location.href);
  const identifyProps = {
    ...traits,
    ...utmParams,
    app: "lu_admin",
  };

  // Call segment.identify
  (window as any)?.analytics?.identify(user_id, identifyProps);

  // Call posthog.identify with additional checks
  if (typeof window !== "undefined" && (window as any)?.posthog?.__loaded) {
    (window as any)?.posthog?.identify(user_id, identifyProps);
  }

  if (process.env.STAGE !== "prod") {
    // tslint:disable-next-line:no-console
    console.log("identify", { user_id, traits: identifyProps });
  }
};

export const trackEvent = (
  event: Event | string,
  properties?: Record<string, any>) => {

  const utmParams = extractUtmParams(window.location.href);
  const trackingProps = {
    ...properties,
    ...utmParams,
    app: "lu_admin",
  };

  (window as any)?.analytics?.track(event, trackingProps);

  // Call posthog.track with additional checks
  if (typeof window !== "undefined" && (window as any)?.posthog?.__loaded) {
    (window as any)?.posthog?.capture(event, trackingProps);
  }

  if (process.env.STAGE !== "prod") {
    // tslint:disable-next-line:no-console
    console.log(event, trackingProps);
  }
};

export const trackEventLegacy = (
  email: string,
  groups: string,
  event: Event,
  metadata?: string) => {

  // Add lu1 prefix to match KS naming convention
  trackEvent(`lu1_${event}`, {
    event_description: `lu1_${event}`,
    email,
    user_type: groups,
    metadata,
    build_time: process.env.BUILD_TIMESTAMP,
  });
};
